/* stackslide (top, bottom - full width) */

.s-alert-effect-stackslide .s-alert-close {
    background: transparent;
}

.s-alert-effect-stackslide .s-alert-close::before,
.s-alert-effect-stackslide .s-alert-close::after {
    background: #fff;
}

.s-alert-effect-stackslide p {
    padding: 0 1.5em;
    display: inline-block;
}

.s-alert-effect-stackslide .s-alert-close::before,
.s-alert-effect-stackslide .s-alert-close::after {
    width: 2px;
    background: #fff;
}

.s-alert-effect-stackslide .s-alert-close:hover::before,
.s-alert-effect-stackslide .s-alert-close:hover::after {
    background: #fff;
}

.s-alert-top-left.s-alert-effect-stackslide.s-alert-show,
.s-alert-top-right.s-alert-effect-stackslide.s-alert-show,
.s-alert-top-left.s-alert-effect-stackslide.s-alert-hide,
.s-alert-top-right.s-alert-effect-stackslide.s-alert-hide,
.s-alert-top.s-alert-effect-stackslide.s-alert-show,
.s-alert-top.s-alert-effect-stackslide.s-alert-hide {
    -webkit-animation-name: animSlideTop;
    animation-name: animSlideTop;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
}

@-webkit-keyframes animSlideTop {
    0% { -webkit-transform: translate3d(0,-100%,0); }
    100% { -webkit-transform: translate3d(0,0,0); }
}

@keyframes animSlideTop {
    0% { -webkit-transform: translate3d(0,-100%,0); transform: translate3d(0,-100%,0); }
    100% { -webkit-transform: translate3d(0,0,0); transform: translate3d(0,0,0); }
}

.s-alert-bottom-left.s-alert-effect-stackslide.s-alert-show,
.s-alert-bottom-right.s-alert-effect-stackslide.s-alert-show,
.s-alert-bottom-left.s-alert-effect-stackslide.s-alert-hide,
.s-alert-bottom-right.s-alert-effect-stackslide.s-alert-hide,
.s-alert-bottom.s-alert-effect-stackslide.s-alert-show,
.s-alert-bottom.s-alert-effect-stackslide.s-alert-hide {
    -webkit-animation-name: animSlideBottom;
    animation-name: animSlideBottom;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
}

@-webkit-keyframes animSlideBottom {
    0% { -webkit-transform: translate3d(0,100%,0); }
    100% { -webkit-transform: translate3d(0,0,0); }
}

@keyframes animSlideBottom {
    0% { -webkit-transform: translate3d(0,100%,0); transform: translate3d(0,100%,0); }
    100% { -webkit-transform: translate3d(0,0,0); transform: translate3d(0,0,0); }
}
